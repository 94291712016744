import React, { forwardRef, PropsWithChildren } from 'react';
import { merge } from 'lodash';
import { useTranslation } from 'react-i18next';

import { SearchSelectInputProps } from './types';
import useStyles from './hooks/useStyles';
import useDataFetchQuery from './hooks/useDataFetchQuery';

import { Select } from '@mantine/core';
import { Icon, Spinner, Box, useTheme } from '@wegroup/design-system';
import SearchSelectInputItem from './SearchSelectInputItem';

interface IconChevronDownProps {
  rightSectionHidden?: boolean;
}

const IconChevronDown: React.FC<
  React.PropsWithChildren<IconChevronDownProps>
> = ({ rightSectionHidden }) => {
  return (
    <Box mr="-1">
      <Icon
        name="IconChevronDown"
        color={rightSectionHidden ? 'transparent' : 'gray.300'}
      />
    </Box>
  );
};

const SearchSelectInput = forwardRef<
  HTMLInputElement,
  PropsWithChildren<SearchSelectInputProps>
>(({ chakraProps, ...mantineProps }, ref) => {
  const {
    name,
    clearable,
    isLoading,
    prefilled,
    rightSectionHidden,
    extraStyles,
    value,
    placeholder,
    data,
    dataFetcher,
    nothingFound,
    isDisabled,
    isDataFilterDisabled,
    drawer,
  } = mantineProps;

  const { t } = useTranslation();
  const { baseStyles } = useStyles({ prefilled, drawer });
  const { zIndices } = useTheme();

  // Query handling
  const {
    data: fetchedData,
    isFetching,
    setQueryDebounced,
  } = useDataFetchQuery({
    dataFetcher,
    name,
  });

  const RightSection = () => {
    if (dataFetcher && (isLoading || isFetching))
      return <Spinner color="primary.500" />;
    if (value && clearable) return null; // Return null so that it display native clearing cross
    return <IconChevronDown rightSectionHidden={rightSectionHidden} />;
  };

  return (
    <Box {...chakraProps}>
      <Select
        ref={ref}
        name={name}
        withinPortal={false}
        searchable
        onSearchChange={setQueryDebounced}
        styles={extraStyles ? merge(baseStyles, extraStyles) : baseStyles}
        icon={<Icon name="IconSearch" color="gray.200" />}
        transitionDuration={200}
        dropdownPosition="flip"
        dropdownComponent="div"
        itemComponent={SearchSelectInputItem}
        disabled={isDisabled}
        {...mantineProps}
        data={data || fetchedData || []}
        placeholder={placeholder || t('Enter a search term')}
        nothingFound={nothingFound || t('No search results found')}
        {...(isDataFilterDisabled && { filter: () => true })}
        {...(RightSection() && { rightSection: <RightSection /> })}
        {...(mantineProps.withinPortal && { zIndex: zIndices.popover })}
      />
    </Box>
  );
});

export const SearchSelectInputClassName = '.mantine-Select-input';
export const SearchSelectRootClassName = '.mantine-Select-root';

export default SearchSelectInput;
