const cyclingVlaanderen = {
  primary: {
    main: '#0077AA',
    50: '#E1FF01',
    100: '#cbe601',
    150: '#F4FF1B',
    200: '#b4cc01',
    300: '#b4cc01',
    400: '#2C2C2C',
    500: '#191300',
    600: '#2C2C2C',
    700: '#b4cc01',
    750: '#665300',
    800: '#4D3D00',
    850: '#332800',
    900: '#191300',
  },
  secondary: {
    main: '#E1FF01',
    50: '#191300',
    100: '#F2F6A6',
    150: '#ECF38A',
    200: '#E6F17D',
    300: '#191300',
    400: '#CAD15B',
    500: '#E1FF01',
    600: '#cbe601',
    700: '#879901',
    750: '#63670D',
    800: '#E1FF01',
    850: '#332800',
    900: '#191300',
  },
};

export default cyclingVlaanderen;
