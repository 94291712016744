import React from 'react';
import { VStack } from '@wegroup/design-system';
import { ConvoHistoryOffer } from '../../../../../types/ConversationHistory';
import OfferPaymentListItem from './OfferPaymentListItem';

interface Props {
  offerRevisions: ConvoHistoryOffer[];
  shouldUpdate?: boolean;
}

const OfferPaymentList: React.FC<Props> = ({
  offerRevisions,
  shouldUpdate,
}) => {
  if (!offerRevisions.length) return <p>No offers to pay</p>;

  return (
    <VStack spacing="6" align="stretch" w="100%">
      {offerRevisions.map((offer) => (
        <OfferPaymentListItem
          key={offer.id}
          offer={offer}
          shouldUpdate={shouldUpdate}
        />
      ))}
    </VStack>
  );
};

export default OfferPaymentList;
