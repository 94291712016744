import React, { useState } from 'react';
import { CalendarBaseProps } from '@mantine/dates';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Box,
  useDisclosure,
  Button,
  Flex,
  Divider,
  HStack,
} from '@wegroup/design-system';
import 'dayjs/locale/en';
import 'dayjs/locale/nl';
import 'dayjs/locale/fr';

import Calendar from '../Calendar';
import DateInput from '../DatePicker/DateInput';
import TimeInput from '../TimeInput';
import { useTranslation } from 'react-i18next';

export interface DatePickerProps {
  withPresets?: boolean;
  amountOfMonths?: number;
  value: Date | null;
  onChange: (date: Date | null) => void;
  locale?: string;
  maxDate?: Date;
  minDate?: Date;
  placeholder?: string;
  isDisabled?: boolean;
  confirmOnChange?: boolean;
}

const DateTimePickerV2: React.FC<
  React.PropsWithChildren<
    DatePickerProps & Omit<CalendarBaseProps, 'value' | 'onChange'>
  >
> = ({
  withPresets = true,
  amountOfMonths = 1,
  value,
  onChange,
  locale,
  maxDate,
  minDate,
  placeholder,
  isDisabled = false,
  children,
  confirmOnChange,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [chosenDate, setChosenDate] = useState<Date | null>(value || null);

  const handleOnDayChange = (date: Date | null) => {
    const chosenDate = date;

    // We make sure that the chosen date is set on the current time
    // If a value is set we should no longer set the time on the current time
    if (chosenDate && !value) {
      const today = new Date();
      chosenDate.setHours(today.getHours());
      chosenDate.setMinutes(today.getMinutes());
      chosenDate.setSeconds(today.getSeconds());
    } else if (chosenDate && value) {
      chosenDate.setHours(value.getHours());
      chosenDate.setMinutes(value.getMinutes());
      chosenDate.setSeconds(value.getSeconds());
    }

    setChosenDate(chosenDate);

    if (confirmOnChange) {
      onChange(chosenDate);
    }
  };

  const handleOnTimeChange = (date: Date | null) => {
    const chosenDate = value;

    if (chosenDate && date) {
      chosenDate?.setHours(date.getHours());
      chosenDate.setMinutes(date.getMinutes());
      chosenDate.setSeconds(date.getSeconds());
    }

    setChosenDate(chosenDate);

    if (confirmOnChange) {
      onChange(chosenDate);
    }
  };

  const handleOnConfirm = () => {
    if (chosenDate) {
      onChange(chosenDate);
      onClose();
    }
  };

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Box onClick={() => !isDisabled && onToggle()}>
          {/* List all children below, and add a onClick prop on each child so every click can be captured */}
          {React.Children.count(children) > 0 ? (
            children
          ) : (
            <DateInput
              isDisabled={isDisabled}
              onChange={handleOnDayChange}
              value={chosenDate}
              placeholder={placeholder}
              dateFormat="dd/MM/yyyy HH:mm"
            />
          )}
        </Box>
      </PopoverTrigger>
      <PopoverContent w="fit-content">
        <PopoverArrow />
        <PopoverCloseButton top="2" right="2" />
        <PopoverBody p="0">
          <Flex>
            <Box pt="5">
              <Box p="4">
                <Calendar
                  maxDate={maxDate}
                  minDate={minDate}
                  amountOfMonths={amountOfMonths}
                  value={chosenDate}
                  onChange={handleOnDayChange}
                  locale={locale}
                  {...otherProps}
                />
              </Box>
              <Divider color="gray.50" />
              <HStack p="4" spacing="2">
                <TimeInput value={chosenDate} onChange={handleOnTimeChange} />
                {!confirmOnChange && (
                  <Button onClick={handleOnConfirm}>{t('OK')}</Button>
                )}
              </HStack>
            </Box>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default DateTimePickerV2;
