import { LanguageStringMap } from '../../../../types/Common';

export interface Flow {
  info: FlowInfo;
  questionnaire: FlowsQuestionnaire;
}

export interface FlowInfo {
  id: string;
  name: string;
  companies: string[];
  type: string;
  country_code: string;
  supported_countries: string[];
  previous?: string;
  next?: string;
}

export interface FlowTagContent {
  tag: string;
  title: LanguageStringMap;
}

export interface LastAnswer {
  question_id: string;
  group_id: string;
  index?: number;
  created_at: string;
}

export interface FlowsResponse {
  info: FlowInfo;
  questionnaire: FlowsQuestionnaire;
  tag_content: FlowTagContent[];
  answers: FlowsQuestionAnswerAPI[];
  last_answer?: LastAnswer;
  session_id: string;
}

export interface FlowsQuestionnaire {
  groups: FlowsQuestionGroup[];
}

export interface FlowsQuestionGroup {
  id: string;
  tag: string;
  title?: LanguageStringMap;
  description?: LanguageStringMap;
  questions: FlowsQuestion[];
  /** Flows question group is a temporary solution to render COMPANY-scan correctly */
  groups?: FlowsQuestionGroup[];
  conditions?: FlowsConditionsObject;
  duplicate_questions?: string[];
  index?: number;
  entity_key?: string;

  /** Contains metadata to display the question correctly if it contains an index */
  duplicateQuestionData?: {
    amount: number;
    id: string;
  };
}

export interface FlowsAdvisory {
  assistant_question: LanguageStringMap;
  assistant_description: LanguageStringMap;
  assistant_answer?: string;
}

export interface FlowsQuestion {
  id: string;
  tag: string;
  field?: string;
  title?: LanguageStringMap;
  rules: FlowQuestionRules;
  value?: unknown;
  validator?: FlowsQuestionValidator;
  description?: LanguageStringMap;
  conditions?: FlowsConditionsObject;
  error_messages?: FlowsQuestionErrorMessages;
  icon_key?: string;
  index?: number;
  symbol?: string;
}

export type FlowsQuestionWithNewEnum = Omit<FlowsQuestion, 'rules'> & {
  rules: FlowQuestionRulesWithNewEnum;
};

export interface FlowsConditionsObject {
  type: string;
  items: FlowsCondition[];
}

export interface FlowsCondition {
  question_id: string;
  value?: unknown;
  index?: number;
  operation?: 'EQ' | 'IN' | 'GE' | 'GT' | 'LE' | 'LT' | 'ANYSTARTSWITH';
  check_type?: 'FIELD' | 'LEN';
  field?: string;
  inversed?: boolean;
}

/**
 * Should not be used anymore
 * @deprecated
 */
interface OldEnumObject {
  [key: string]: LanguageStringMap | undefined;
}

export interface EnumObject {
  value: string;
  label: LanguageStringMap;
  conditions?: FlowsConditionsObject;
  icon_key?: string;
  description?: string;
  disabled_description?: string;
}

export interface FlowTableRule {
  entity_name: LanguageStringMap;
  entity_name_plural: LanguageStringMap;
  entity_formatted_str?: string;
  delete_method?: 'BY_PARTY_ID' | 'BY_ENTITY_ID';
  index_question_ids?: string[];
  columns: {
    question_id: string;
    title: LanguageStringMap;
  }[];
}

export interface FlowQuestionRules {
  mandatory?: boolean;
  input_type: string;
  data_type?: string | null;
  symbol?: string | null;
  enum?: OldEnumObject[] | EnumObject[] | number[] | null;
  gt?: string | number | null;
  lt?: string | number | null;
  ge?: string | number | null;
  le?: string | number | null;
  min_length?: string | number | null;
  max_length?: string | number | null;
  regex?: FlowsQuestionRegex | null;
  mask?: string;
  sort?: 'asc' | 'desc' | null;
  table?: FlowTableRule;
  indexed_question_id?: string;
  object_string_format?: string;
  is_disabled?: boolean;
  address?: {
    allowed_countries?: string[];
    autocomplete_enabled?: boolean;
  };
}

type FlowQuestionRulesWithNewEnum = Omit<FlowQuestionRules, 'enum'> & {
  enum?: EnumObject[] | null;
};

export interface FlowsQuestionErrorMessages {
  mandatory?: LanguageStringMap | null;
  gt?: LanguageStringMap | null;
  lt?: LanguageStringMap | null;
  ge?: LanguageStringMap | null;
  le?: LanguageStringMap | null;
  min_length?: LanguageStringMap | null;
  max_length?: LanguageStringMap | null;
  regex?: LanguageStringMap | null;
  input_type?: LanguageStringMap | null;
  data_type?: LanguageStringMap | null;
}

export interface FlowsQuestionRegex {
  js: string;
  py: string;
}

export interface FlowsQuestionValidator {
  url: string;
  body: string;
}

export type FlowsQuestionAnswerAction = 'CREATE' | 'UPDATE' | 'DELETE';

export interface FlowsQuestionAnswer {
  id: string;
  groupId: string;
  answer: unknown;
  prefilled?: boolean;
  subViewHistory?: SubViewHistory;
  index?: number;

  /** Used for tracking update state */
  hasInitialValue?: boolean;

  /** Temporary */
  tag?: string;
}

export interface FlowsQuestionAnswerAPI {
  id: string;
  group_id: string;
  answer: unknown;
  index?: number;
}

export interface FlowsPrefillAction {
  id: string;
  answer: unknown;
  groupId?: string;
  onlyInitialQuestionnaire?: boolean;
}

export interface SubViewHistory {
  previous: string[];
  next: string[];
}

export interface DuplicateQuestionMapping {
  [amountGroupId: string]: string[];
}

/**
 * FLOWS RELATED TYPEGUARDS
 */

export const isNewEnumFormat = (
  enumObj?: OldEnumObject[] | EnumObject[] | number[] | null,
): enumObj is EnumObject[] => {
  return (enumObj as EnumObject[])?.every((item) => item.value);
};

export const isTextOptions = (options: unknown): options is OldEnumObject[] => {
  return (options as OldEnumObject) !== undefined;
};

export const isNumberOptions = (options: unknown): options is number[] => {
  return (options as number[])?.every((item) => typeof item === 'number');
};
