import { ColorProps, useTheme, Box } from '@wegroup/design-system';
import { get } from 'lodash';
import React from 'react';
import { hasRichText } from '../../../../../../company-scan/utils/textUtils';
import { useDefaultRichTextEditorStyling } from '../useDefaultRichTextEditorStyling';
import useStyles from '@mantine/rte/esm/components/RichTextEditor/RichTextEditor.styles.js';
import { sanitize } from 'dompurify';

interface Props {
  value: string;
  color?: ColorProps['color'];
}

/**
 * This component should be used for displaying RichTextEditor values in the platform.
 * It is a copy of the RichTextEditor component from the Mantine package, but without
 * the editor functionality.
 *
 * It mainly contains the same HTML structure as the RichTextEditor component, but
 * with no functionality (so no input field).
 */
const RichText: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  color,
}) => {
  const { colors } = useTheme();

  // Importing the styling straight from the mantine package here
  const { classes } = useStyles({}, null);

  // If color provided was NOT HEX (was for example primary.400)
  // It will get the color from our theme colors :)
  const hexColorRx = new RegExp(/^#[0-9a-f]{3,6}$/i);
  if (
    typeof color === 'string' &&
    !hexColorRx.test(color) &&
    color?.toLowerCase() !== 'currentcolor'
  ) {
    color = get(colors, color);
  }

  const defaultStyling = useDefaultRichTextEditorStyling();

  if (!hasRichText(value)) return null;

  return (
    <Box className="mantine-RichTextEditor-root">
      <Box className="quill">
        <Box className="ql-snow">
          <Box
            className={`ql-editor ${classes.root}`}
            sx={{
              ...defaultStyling.root,
              'p, li, div': {
                color: `${color} !important`,
              },
              background: 'transparent',
              border: 'none',
            }}
            dangerouslySetInnerHTML={{ __html: sanitize(value) }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RichText;
