import React, { useEffect, useState } from 'react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import Iubenda from '../../../../common/src/utils/Iubenda';
import {
  PreferenceExpressed,
  TRACKING_COOKIE,
} from '../../../../../types/CookieBanner';
import useAuth from '../../features/authentication/hooks/useAuth';
import * as Sentry from '@sentry/react';
import { isSocialLoginUrl } from '../../features/authentication/constants/SocialAuth';
import { usePostHog } from 'posthog-js/react';

export const LogrocketProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [hasLogrocketStarted, setHasLogrocketStarted] = useState(false);
  const {
    ssoData: { info: userInfo },
    campaign,
  } = useAuth();
  const posthog = usePostHog();

  const filteredBids = [
    // E2E staging bid
    'a4df0a39-dec1-4b97-a8b6-8624461fabb5',
    '5eb3fb28-7432-4c2e-ac66-0fc5be7e8fec',
    // E2E prod bid
    'dd3ce910-04a4-4c68-a569-8cbb8677a6f9',
  ];
  const filteredOrigin = ['random'];

  const shouldTrackByEnv =
    process.env.NODE_ENV !== 'development' &&
    process.env.REACT_APP_ENVIRONMENT !== 'QA';

  const shouldTrackByUser =
    !filteredBids.includes(userInfo?.bid || '') &&
    !filteredOrigin.includes(userInfo?.origin || '');

  const initTracking = () => {
    LogRocket.init('7l7lpz/broker-insurance-needs');
    setupLogRocketReact(LogRocket);
    // Posthog start
    if (shouldTrackCampaign) {
      posthog?.opt_in_capturing();
      posthog?._start_queue_if_opted_in();
      posthog?.startSessionRecording();
      sessionStorage.setItem('posthog_session_id', posthog?.get_session_id());
      sessionStorage.setItem(
        'posthog_session_url',
        posthog?.get_session_replay_url(),
      );
      posthog?.setPersonProperties({
        isCampaign: true,
      });
      if (userInfo?.email) {
        posthog?.setPersonProperties({
          email: userInfo?.email,
        });
      }
    }
    // Identify user
    if (userInfo?.email) {
      LogRocket.identify(userInfo?.bid || 'Email Login', {
        email: userInfo?.email,
      });
      Sentry.setUser({ email: userInfo?.email });
    }
    setHasLogrocketStarted(true);
  };
  const shouldTrackCampaign =
    isSocialLoginUrl && !filteredBids.includes(campaign?.created_by || '');

  useEffect(() => {
    if (!hasLogrocketStarted || !posthog?.has_opted_in_capturing()) {
      if (userInfo) {
        if (shouldTrackByEnv && shouldTrackByUser) {
          initTracking();
        }
      }
    }
  }, [userInfo, campaign]);

  const handleCookiePreferenceGiven = (e: Event) => {
    const preferences = e['detail'] as PreferenceExpressed;
    // This will check if the fourth purpose ("tracking") is enabled in the cookie banner
    if (
      preferences.purposes[TRACKING_COOKIE] &&
      shouldTrackByEnv &&
      shouldTrackCampaign
    ) {
      initTracking();
    } else {
      setHasLogrocketStarted(false);
      posthog?.opt_out_capturing();
    }
  };

  useEffect(() => {
    document.addEventListener(
      'cookie-preference-given',
      handleCookiePreferenceGiven,
    );
    return () => {
      document.removeEventListener(
        'cookie-preference-given',
        handleCookiePreferenceGiven,
      );
    };
  }, []);

  const showCookieBanner =
    isSocialLoginUrl && shouldTrackByEnv && shouldTrackCampaign && campaign;

  return (
    <>
      {showCookieBanner && <Iubenda themeName={campaign?.theme?.name} />}
      {children}
    </>
  );
};
