import {
  LanguageStringMap,
  FullDateObject,
  LegacyPagination,
  Language,
} from './Common';
import React from 'react';
import { OfferState } from '../packages/sales-conversation/src/services/offersService';

export interface KVPair {
  key: string;
  name: string;
}

export interface InsuranceOption {
  key: string;
  name: string;
  description: string;
}

export interface InsuredValue {
  value: number;
  max_value?: number;
  evaluation_type: string;
}

export interface Franchise {
  title: string;
  type: string;
  value: number;
  level?: string;
}
export interface Formulas {
  [key: string]: string[];
}

export interface ApiCoverage {
  source: string;
  status: string;
  confidence: number;
  last_updated: {
    day: string;
    month: string;
    year: string;
    hour: string;
    minute: string;
    second: string;
  };
}

export interface Warning {
  description: LanguageStringMap;
}

export interface Discount {
  name: string;
  applied: number;
  max: number;
}

export interface QuoteOption {
  key: string;
  name: string;
  description: string;
  taxes: number;
  net_premium: number;
  commission: number;
  total_premium: number;
  taxes_in_euro: number;
  formula: string;
  mandatory: boolean;
  required: string[];
}

export interface QuoteData {
  base: {
    key: string;
    name: string;
    description: string;
    taxes: number;
    taxes_in_euro: number;
    commission: number;
    net_premium: number;
    total_premium: number;
    formula: string;
  };
  details: {
    language: string;
    currency: string;
    payment_interval: string;
    payment_intervals: string[];
    split_costs: { interval: string; cost: number };
    insured_value: InsuredValue & {
      reconstruction_with_new_standards: boolean;
    };
    insured_content_value: InsuredValue;
    ck: number;
    max_ck: number;
    vk: number;
    max_vk: number;
    discounts: Discount[];
    franchise: Franchise;
    franchises: Franchise[];
    index: string;
    bonus_malus: number;
    abex: string;
    formulas: Formulas;
    api_coverage?: ApiCoverage;
    evaluation_type: string;
    price_range: {
      min: number;
      max: number;
      multiplier: number;
    };
    warnings: Warning[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata?: any;
    contract_commencement_date: Date;
    bonus_malus_can_edit?: boolean;
  };
  options: QuoteOption[];
}
export interface Modifications {
  multipliers: { total: number };
  addition: { total: number };
  modifiers: { total: number };
}

export interface Criteria {
  start_date: FullDateObject;
  end_date: FullDateObject;
  required_guarantees: string[];
  risk_object_checks: string[];
  modifications: Modifications;
}

export interface Promotion {
  id: string;
  title: LanguageStringMap;
  description: LanguageStringMap;
  criteria: Criteria;
  eligible: boolean;
  applied: boolean;
}

export interface Insurance {
  name: string;
  company: string;
  type: string;
  affinity: string;
}

export interface QuotePremium {
  payment_interval: string;
  total_premium: number;
  net_premium: number;
  taxes_in_euro: number;
  policy_costs: number;
  recurring_policy_costs: number;
  commission: number;
  commission_in_euro: number;
  currency: string;
  insurance_share: number;
  id: string | null;
  franchise?: Franchise | null;
  bonus_malus?: number | null;
  insured_value?: InsuredValue | null;
  insured_content_value?:
    | (InsuredValue & {
        reconstruction_with_new_standards: boolean;
      })
    | null;
  abex?: number | null;
}

export interface Quote {
  id: string;
  premium: QuotePremium;
  quote: QuoteData;
  promotions: Promotion[];
  insurance: Insurance;
  language: string;
}

export interface InsuranceCompany extends KVPair {
  logo: string;
  website: string;
}
export interface InsuranceType {
  key: string;
}

export interface Specs {
  bonus_malus?: number;
  evaluation_car?: string;
  franchise?: Franchise;
  evaluation_building?: string;
  insured_value?: number;
  insured_content_value?: number;
  ck?: number;
  vk_percentage?: number;
  modifications?: { name: string; formula: string }[];
  payment_interval?: string;
  defaultValues?: { [x: string]: React.ReactNode };
  flat_commission_discount?: number;
}

export enum QuoteStatus {
  SUCCESS,
  STARTED,
  CALCULATING,
  FAILURE,
}

export interface BadQuoteResp {
  detail?: LanguageStringMap;
  code: number;
}

export interface QuoteRequest {
  id: string;
  promise: Promise<[Quote & BadQuoteResp, number] | undefined[]>;
}

export interface Guarantee {
  title: LanguageStringMap;
  description: LanguageStringMap;
  tag: string;
  mandatory: boolean;
  required?: string[];
  one_of: boolean;
}

export interface Tags {
  [x: string]: Guarantee[];
}

export interface GuaranteesResponse {
  tags: Tags;
}

export interface QuoteOptionsMetaData {
  key: string;
  name: string;
  description: string;
}

export interface SanitizedQuote {
  id: string;
  CRM: KVPair;
  insurance: InsuranceOption;
  insuranceCompany: InsuranceCompany;
  insuranceType: InsuranceType;
  guarantees: {
    key: string;
    name: string;
  }[];
  options: QuoteOptionsMetaData[];
  language: string;
  links: {
    ipid?: string;
    terms?: string;
  };
  premium: QuotePremium;
  promotions: Promotion[];
  quote: QuoteData;
  error?: {
    code: number;
    detail: string;
  };
  calcError?: LanguageStringMap;
  specs: Specs;
  status: QuoteStatus;
  chosenOptions: string[];
  chosenPromotions: string[];
  isOpen: boolean;
  focusedInputId: string;
  requests: QuoteRequest[];
}

export interface CancelledInsuranceValues {
  reasonOfTermination: string;
  insuranceCompany: string;
  policyNumber: string;
  expiryDate?: string;
}

// REST API

export interface CalculationSpec {
  description?: string;
  disabled: boolean;
  calc_type: string;
  input_type: 'CURRENCY' | 'SELECT' | 'INPUT';
  enum?: {
    disabled: boolean;
    value: string;
    label: LanguageStringMap;
  }[];
  max_value?: number;
  specs_field: string;
  title: LanguageStringMap;
  value?: number;
}

export interface QuoteDetails {
  language: string;
  currency: string;
  payment_interval: string;
  payment_intervals: string[];
  split_costs: { interval: string; cost: number };
  insured_value?: InsuredValue & {
    reconstruction_with_new_standards: boolean;
  };
  calculation_specs?: CalculationSpec[];
  insured_content_value?: InsuredValue;
  ck: number;
  max_ck: number;
  vk: number;
  max_vk: number;
  discounts: Discount[];
  franchise: Franchise;
  franchises: Franchise[];
  index: string;
  bonus_malus: number;
  abex: string;
  formulas: Formulas;
  api_coverage?: ApiCoverage;
  evaluation_type: string;
  price_range: {
    min: number;
    max: number;
    multiplier: number;
  };
  warnings: Warning[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata?: any;
  contract_commencement_date: Date;
  possible_insurances: string[];
  formulas_v2?: FormulasV2;
}

export interface FormulasV2 {
  [x: string]: {
    description: string;
    id: string;
    name: Language;
  }[];
}

export interface QuoteGuaranteeContent {
  key: string;
  name: string;
  description: string;
  id: string;
}

export interface QuoteGuaranteeOption {
  name: string;
  icon_key: string;
  taken: boolean;
}

export interface QuoteGuarantee {
  commision: number;
  mandatory: boolean;
  name: string;
  net_premium: number;
  required: string[];
  taxes: number;
  taxes_in_euro: number;
  total_premium: number;
}

export interface QuoteInfo {
  company: {
    key: string;
    logo?: string;
    name?: string;
  };
  ipid?: string | null;
  terms?: string | null;
  insurance_name: string;
  insurance_type: string;
}

export interface QuoteInsurance {
  affinity: string | null;
  company: string;
  ipid?: string;
  name: string;
  terms?: string;
  type: string;
}

export interface LinkedQuote {
  guarantees: string[];
  id: string;
  self: string;
}
export interface Score {
  value: number | string;
  type: string;
}
export interface QuoteCoverageGroup {
  coverages: SubCoverage[];
  description: string | null;
  icon_key: string;
  is_important_to_customer: boolean;
  key: string;
  name: string;
  rating: number;
  type?: string;
}

export interface SubCoverage {
  key: string;
  name: string;
  description: string | null;
  coverage_limit: Score;
}

export interface QuoteQuality {
  coverage_groups: QuoteCoverageGroup[];
  key: string;
  name: string;
}

export interface QuoteResponse {
  code: number;
  id: string;
  hash_id: string;
  selected_guarantee_tags: string[];
  selected_guarantees: string[];
  possible_guarantees: string[];
  info: QuoteInfo;
  premium: QuotePremium;
  guarantees: QuoteGuarantee[];
  quality?: QuoteQuality[];
  details: QuoteDetails;
  promotions: string[];
  matching_score: number;
  guarantee_content: QuoteGuaranteeContent[];
}

export interface Product {
  hash_id: string;
  self: string;
  info: QuoteInfo;
  selected_guarantees: string[];
  possible_guarantees: string[];
  selected_guarantees_info_ids: string[];
  matching_score: null;
  quality: null;
  guarantee_content: QuoteGuaranteeContent[] | null;
  linked_quotes: null;
}

export interface ProductWithQuality {
  hash_id: string;
  self: string;
  info: QuoteInfo;
  selected_guarantee_tags: string[];
  selected_guarantees: string[];
  possible_guarantees: string[];
  possible_insurances?: string[];
  matching_score?: number;
  quality?: QuoteQuality[];
  risk_object_revision_id?: string;
  guarantee_content?: QuoteGuaranteeContent[] | null;
  linked_quotes?: string[] | null;
}

export interface RiskObjectError {
  tag?: string | null;
  company: string;
  insurance_type: string;
  field: string;
  detail: LanguageStringMap;
}

export interface ProductsQualityResp {
  [x: string]: ProductWithQuality[];
}
export type ProductsQualityRespWithPagination = ProductsQualityResp & {
  risk_object_errors: RiskObjectError[];
  pagination: LegacyPagination;
};

export interface LegacyProductResp {
  [x: string]: Product[];
}
export type ProductsRespWithPagination = LegacyProductResp & {
  pagination: LegacyPagination;
};

export type LouiseQuote = {
  bestOffer?: boolean;
} & QuoteResponse;

export type LouiseQuoteWithQuality = LouiseQuote & {
  quality: QuoteQuality[];
};

// Typeguard for failed quote
export const isFailedQuote = (
  quote?: LouiseQuote | FailedLouiseQuote,
): quote is FailedLouiseQuote => Boolean(quote && quote.code >= 400);

export interface FailedLouiseQuote {
  code: number;
  detail: LanguageStringMap;
  domain?: string | null;
  error?: string | null;
  formatted?: string | null;
  msg?: string | null;
  rpc_code: number;
}

export type SuccessLouiseOffer = ProductWithQuality & {
  // Price related
  premium: QuotePremium;
  guarantees: QuoteGuarantee[];
  details: QuoteDetails;
  promotions: string[];
  // Others
  state: OfferState;
  linked_quotes: string[] | null;
  bestOffer?: boolean;
  id?: string;
};

export const isOfferSuccessfull = (
  offer: LouiseOffer,
): offer is SuccessLouiseOffer => offer?.state === OfferState.FETCHED;

export type ErrorLouiseOffer = ProductWithQuality & {
  // Others
  state: OfferState;
  linked_quotes: string[] | null;
  bestOffer?: boolean;
  errorMessage: string;
  code: number;
};

export const isOfferFailed = (offer: LouiseOffer): offer is ErrorLouiseOffer =>
  offer?.state === OfferState.ERROR;

export type LouiseOffer = ProductWithQuality & {
  // Price related
  premium?: QuotePremium;
  guarantees?: QuoteGuarantee[];
  details?: QuoteDetails;
  promotions?: string[];
  // Others
  state: OfferState;
  linked_quotes: string[] | null;
  bestOffer?: boolean;
  errorMessage?: string;
  code?: number;
  id?: string;
};

export interface ProductErrorResponse {
  msg: string;
  code: number;
}

export const isLouiseQuoteWithQuality = (
  quote: LouiseQuote | LouiseQuoteWithQuality,
): quote is LouiseQuoteWithQuality => !!quote.quality;
