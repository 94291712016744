import React from 'react';
import { Card, Stack, StackDivider } from '@wegroup/design-system';
import { ConvoHistoryOffer } from '../../../../../types/ConversationHistory';
import OfferPaymentListItemRight from './OfferPaymentListItemRight';
import OfferPaymentListItemLeft from './OfferPaymentListItemLeft';
import LoadingSpinner from '../../../../common/src/components/LoadingSpinner';
import { useMinimalOffer } from '../../../../parties/src/api/getMinimalOfferById';

interface Props {
  offer: ConvoHistoryOffer;
  shouldUpdate?: boolean;
}

const OfferPaymentListItem: React.FC<Props> = ({ offer, shouldUpdate }) => {
  const {
    data: offerData,
    isLoading: isLoadingOffer,
    isError,
  } = useMinimalOffer(
    {
      offerId: offer.id,
      revision: offer.revision,
    },
    {
      refetchOnWindowFocus: true,
      refetchInterval: (_data, query) => {
        /** Check for new updates on the offer every 2 sec for a maximum of 10 times */
        if (query.state.dataUpdateCount < 10 && shouldUpdate) {
          return 2000;
        }
        return false;
      },
    },
  );

  const isPaid = offerData?.payments?.[offerData.payments.length - 1]?.is_paid;

  if (isLoadingOffer) return <LoadingSpinner />;

  if (!offerData || isError) return <p>Failed to load offer</p>;

  return (
    <Card p="0" borderColor={isPaid ? 'success.main' : 'gray.50'}>
      <Stack
        spacing="0"
        direction={{ base: 'column', tabletS: 'row' }}
        align="stretch"
        divider={<StackDivider w="1px" bg="gray.50" />}
      >
        <OfferPaymentListItemLeft
          offer={offerData}
          insuranceName={offerData.insurance.name}
        />
        <OfferPaymentListItemRight
          premium={offerData.premium}
          downloadUrl={offerData.documents.unsigned[0]?.link}
          paymentUrl={
            offerData.payments?.[offerData.payments.length - 1]?.payment_url
          }
          termsUrl={offerData.insurance?.terms}
          isPaid={isPaid}
        />
      </Stack>
    </Card>
  );
};

export default OfferPaymentListItem;
