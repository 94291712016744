import { MantineStylesObject } from '../types';
import { useTheme, useFormControlContext } from '@wegroup/design-system';
import { chakraCustomScrollBar } from '../../../../../../common/src/utils/styledComponentsUtils';

interface Props {
  prefilled?: boolean;
  drawer?: boolean;
}

/**
 * Generator of the css styling for the Mantine select component, for both desktop and mobile
 * @returns `styles` object that can be fed to the mantine component
 */

const useStyles = (
  props: Props = {},
): Record<'baseStyles' | 'drawerStyles', MantineStylesObject> => {
  const { isInvalid } = useFormControlContext() || {};

  const { prefilled, drawer } = props;
  const { space, colors, radii, textStyles, shadows } = useTheme();

  const fontStyling = {
    ...textStyles['normalBody'],
    fontFamily: 'Circular, Helvetica, Myriad, sans-serif',
    fontSize: '16px',
  };

  const borderStyling = {
    border: '1px solid',
    borderRadius: radii['md'],
    borderColor: colors.gray['100'],
  };

  const baseStyles = {
    input: {
      ...fontStyling,
      ...borderStyling,
      borderColor: isInvalid
        ? colors.danger.main
        : prefilled
        ? '#FFBC0F'
        : colors.gray['100'],
      height: space['10'],
      transition: 'all 0.2s',
      background: prefilled ? '#FFF9EA' : 'white',

      ':focus': {
        borderRadius: radii['md'],
        borderColor: colors.primary['500'],
      },
      ':focus-within': {
        borderRadius: radii['md'],
        borderColor: colors.primary['500'],
      },

      '::placeholder': {
        color: 'default',
      },

      ':disabled': {
        borderColor: isInvalid
          ? colors.danger.main
          : prefilled
          ? '#FFBC0F'
          : colors.gray['100'],
        background: prefilled ? '#FFF9EA' : 'white',
        opacity: 0.4,
      },
    },
    dropdown: {
      ...chakraCustomScrollBar,
      ...borderStyling,
      boxShadow: shadows.level4,
      '> div > div > div': {
        padding: '0 !important',
      },
      ...(drawer && {
        border: 'none',
        boxShadow: 'none',
        maxHeight: 'auto',
        '> div ': {
          maxHeight: '100% !important',
        },
      }),
    },

    item: {
      ...fontStyling,

      borderBottom: '1px solid',
      borderColor: colors.gray['100'],
      borderRadius: '0',

      transition: 'all 0.2s',

      paddingTop: space['3'],
      paddingBottom: space['3'],
      paddingLeft: space['4'],
      paddingRight: space['4'],

      '&[group]': {
        borderBottom: 'none',
      },
      '&[data-hovered]': {
        color: 'black',
        backgroundColor: colors.gray['50'],
      },

      ':last-of-type': {
        borderBottom: 'none',
      },
      '&[styling="SELECT-ALL"]': {
        textDecoration: 'underline',
      },
      '&[data-selected]': {
        color: colors.primary['500'],
        backgroundColor: colors.primary['50'],

        '&:hover': {
          color: colors.primary['500'],
          backgroundColor: colors.primary['100'],
        },
      },
    },
    separatorLabel: {
      ...textStyles['largeBodyMedium'],
    },
    error: {
      ...fontStyling,
      ...textStyles['extraSmallBody'],
      textAlign: 'right',
    },
    icon: {
      zIndex: 0,
    },
    rightSection: {
      width: space[9],
      pointerEvents: 'none',
    },
  };

  const drawerStyles = {
    root: {
      height: '100%',
      '> div': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '> div:nth-of-type(2)': {
          flex: '1',
          '> div': {
            height: '100%',
            '> div': {
              height: '100%',
              transform: 'unset !important',
              width: '100% !important',
              '> div': {
                height: '100%',
                '> div': {
                  height: '100%',
                  maxHeight: 'unset !important',
                },
              },
            },
          },
        },
      },
    },
    wrapper: {
      width: `calc(100% - ${parseInt(space['4']) * 2}px)`,
      margin: '0 auto',
    },
  };

  return {
    // Base styling (desktop)
    baseStyles,
    // Drawer styling (mobile)
    drawerStyles,
  };
};

export default useStyles;
